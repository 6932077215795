import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { createLandscape, dispose } from './lib/script';
import palleteImage from './lib/pallete5.png';

const InteractiveLandscape = ({ className, children }) => {
  const canvas = useRef(null);

  useLayoutEffect(() => {
    dispose();
    if (canvas.current) createLandscape({ canvas: canvas.current, palleteImage });

    return () => dispose();
  }, [canvas]);

  return (
    <div style={{ height: '100vh' }} className={className}>
      <canvas ref={canvas} className="landscape" />
      {children}
    </div>
  );
};

InteractiveLandscape.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
export default InteractiveLandscape;
