/* eslint-disable max-lines */
import * as THREE from 'three';
import vertex from './shaders/vertex';
import fragments from './shaders/fragmets';
import { Sky } from './Sky';

let scene;

let renderer;

let camera;

let terrain;

export const createLandscape = params => {
  let width = window.outerWidth;

  let height = window.innerHeight;

  const mouse = { x: 0, y: 0, xDamped: 0, yDamped: 0 };

  const isMobile = typeof window.orientation !== 'undefined';

  const sceneSetup = () => {
    scene = new THREE.Scene();
    const fogColor = new THREE.Color(0xffffff);

    scene.background = fogColor;
    scene.fog = new THREE.Fog(fogColor, 10, 400);

    sky();

    camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 10000);
    camera.position.y = 8;
    camera.position.z = 4;

    const ambientLight = new THREE.AmbientLight(0xffffff, 1);

    scene.add(ambientLight);

    renderer = new THREE.WebGLRenderer({
      canvas: params.canvas,
      antialias: true,
    });
    renderer.setPixelRatio = devicePixelRatio;
    renderer.setSize(width, height);
  };

  const sceneElements = () => {
    const geometry = new THREE.PlaneBufferGeometry(100, 400, 400, 400);

    const uniforms = {
      time: { type: 'f', value: 0.0 },
      distortCenter: { type: 'f', value: 0.1 },
      roadWidth: { type: 'f', value: 0.5 },
      pallete: { type: 't', value: null },
      speed: { type: 'f', value: 0.5 },
      maxHeight: { type: 'f', value: 10.0 },
      color: new THREE.Color(1, 1, 1),
    };

    const material = new THREE.ShaderMaterial({
      uniforms: THREE.UniformsUtils.merge([THREE.ShaderLib.basic.uniforms, uniforms]),
      vertexShader: vertex,
      fragmentShader: fragments,
      wireframe: false,
      fog: true,
    });

    terrain = new THREE.Mesh(geometry, material);
    terrain.position.z = -180;
    terrain.rotation.x = -Math.PI / 2;

    scene.add(terrain);
  };

  const sceneTextures = () => {
    // pallete
    new THREE.TextureLoader().load(params.palleteImage, texture => {
      terrain.material.uniforms.pallete.value = texture;
      terrain.material.needsUpdate = true;
    });
  };

  const sky = () => {
    const _sky = new Sky();

    _sky.scale.setScalar(450000);
    _sky.material.uniforms.turbidity.value = 20;
    _sky.material.uniforms.rayleigh.value = 1;
    _sky.material.uniforms.luminance.value = 0;
    _sky.material.uniforms.mieCoefficient.value = 0.001;
    _sky.material.uniforms.mieDirectionalG.value = 0.5;

    scene.add(_sky);

    const sunSphere = new THREE.Mesh(
      new THREE.SphereBufferGeometry(20000, 16, 8),
      new THREE.MeshBasicMaterial({ color: 0xffffff }),
    );

    sunSphere.visible = false;
    scene.add(sunSphere);

    const theta = Math.PI * -0.02;

    const phi = 2 * Math.PI * -0.25;

    sunSphere.position.x = 400000 * Math.cos(phi);
    sunSphere.position.y = 400000 * Math.sin(phi) * Math.sin(theta);
    sunSphere.position.z = 400000 * Math.sin(phi) * Math.cos(theta);

    _sky.material.uniforms.sunPosition.value.copy(sunSphere.position);
  };

  const resize = () => {
    width = window.outerWidth;
    height = window.innerHeight;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    renderer.setSize(width, height);
  };

  const onInputMove = e => {
    let x;

    let y;

    if (e.type === 'mousemove') {
      x = e.clientX;
      y = e.clientY;
    } else {
      x = e.changedTouches[0].clientX;
      y = e.changedTouches[0].clientY;
    }

    mouse.x = x;
    mouse.y = y;
  };

  const render = () => {
    requestAnimationFrame(render);

    // damping mouse for smoother interaction
    mouse.xDamped = lerp(mouse.xDamped, mouse.x, 0.1);
    mouse.yDamped = lerp(mouse.yDamped, mouse.y, 0.1);

    const time = performance.now() * 0.001;

    terrain.material.uniforms.time.value = time;
    terrain.material.uniforms.distortCenter.value = map(mouse.xDamped, 0, width, -0.1, 0.1);
    terrain.material.uniforms.roadWidth.value = map(mouse.yDamped, 0, height, -0.5, 2.5);

    renderer.render(scene, camera);
  };

  const map = (value, start1, stop1, start2, stop2) => {
    return start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
  };

  const lerp = (start, end, amt) => {
    return (1 - amt) * start + amt * end;
  };

  const init = () => {
    sceneSetup();
    sceneElements();
    sceneTextures();
    render();

    if (isMobile) window.addEventListener('touchmove', onInputMove, { passive: false });
    else window.addEventListener('mousemove', onInputMove);

    window.addEventListener('resize', resize);
    resize();
  };

  init();
};
export const dispose = () => {
  if (typeof camera?.dispose === 'function') camera?.dispose();
  if (typeof terrain?.dispose === 'function') terrain?.dispose();
  if (typeof scene?.remove === 'function') scene?.remove();
  if (typeof scene?.dispose === 'function') scene?.dispose();
  if (typeof renderer?.dispose === 'function') renderer?.dispose();
};
