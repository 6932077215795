import { TweenMax, Quad, Expo } from 'gsap';

const getRandomNumber = (min, max) => Math.random() * (max - min) + min;

export const animateTitles = (overlay, titleLettersRefs) => {
  TweenMax.to(overlay, 2, {
    ease: Quad.easeOut,
    opacity: 0,
  });
  const titleLetters = titleLettersRefs.map(o => o.current);

  TweenMax.set(titleLetters, { opacity: 0 });
  TweenMax.staggerTo(
    titleLetters,
    1.5,
    {
      ease: Expo.easeOut,
      startAt: { rotationX: -100, z: -1000 },
      opacity: 1,
      rotationX: 0,
      z: 0,
    },
    0.1,
  );

  const glitch = (el, cycles) => {
    if (cycles === 0 || cycles > 3) return;
    TweenMax.set(el, {
      x: getRandomNumber(-20, 20),
      y: getRandomNumber(-20, 20),
      color: ['#95dc77', '#f3eb8a', '#f9b97f'][cycles - 1],
    });
    setTimeout(() => {
      TweenMax.set(el, { x: 0, y: 0, color: '#fff' });
      glitch(el, cycles - 1);
    }, getRandomNumber(20, 100));
  };

  return setInterval(
    () =>
      setTimeout(() => {
        const titleLettersShuffled = titleLetters.sort((a, b) => 0.5 - Math.random());
        const lettersSet = titleLettersShuffled.slice(0, getRandomNumber(1, titleLetters.length + 1));

        for (let i = 0, len = lettersSet.length; i < len - 1; ++i) {
          glitch(lettersSet[i], 3);
        }
      }, getRandomNumber(500, 3000)),
    500,
  );
};
