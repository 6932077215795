import { useWindowSize } from '@react-hook/window-size';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { effects } from './lib/effects';
import Sketch from './lib/Sketch';

const _images = [
  '/assets/images/shooting/1.webp',
  '/assets/images/shooting/2.webp',
  '/assets/images/shooting/3.webp',
  '/assets/images/shooting/4.webp',
  '/assets/images/shooting/5.webp',
];
const handleSetup = async sket => {
  await sket.initiate();
  sket.settings();
  sket.addObjects();
  sket.resize();
  sket.play();
};
const FancySlider = ({ images = _images, effect = '1', children }) => {
  const slider = useRef();
  const canvas = useRef();
  const [sketch, setSketch] = useState(null);
  const size = useWindowSize();

  useEffect(() => {
    if (slider.current && canvas.current && sketch === null) {
      const _sketch = new Sketch({ ...effects[effect], images, slider: slider.current, canvas: canvas.current });

      setSketch(_sketch);
      handleSetup(_sketch);
    }
  }, [slider, canvas, sketch, images, effect]);

  useEffect(() => {
    if (sketch !== null) sketch.resize();
  }, [size, sketch]);
  const slide = y => {
    if (sketch !== null) {
      y < 0 ? sketch.next() : sketch.previous();
    }
  };

  return (
    <div className="fancy-slider-wrapper">
      <div onClick={() => slide(-1)} className=" right-0 top-0 h-100 w-50 z-index-999 position-absolute" />

      <div onClick={() => slide(1)} className=" left-0 top-0 h-100 w-50 z-index-999 position-absolute" />
      <div className="fancy-slider" ref={slider}>
        <canvas ref={canvas} />
      </div>
    </div>
  );
};

FancySlider.propTypes = {
  children: PropTypes.node,
  images: PropTypes.arrayOf(PropTypes.string),
  effect: PropTypes.string,
};
export default FancySlider;
