import React from 'react';
import InteractiveLandscape from 'components/InteractiveLandscape';
import FancyTitle from 'components/FancyTitle';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { changeColorOnVisible } from 'hooks/changeColorOnScroll';
import ReactTyped from 'react-typed';
import { useDispatch } from 'react-redux';

const HomeHeroSection = ({ nextSectionColor }) => {
  // const { t } = useTranslation();
  // const [bottomSectionColor, setBottomSectionColor] = useState('#fff');
  const disptach = useDispatch();

  return (
    <div>
      <InteractiveLandscape className="position-relative ">
        <div className="black-overlay" />
        <ReactVisibilitySensor
          partialVisibility
          onChange={isVisible => {
            changeColorOnVisible('#fff', isVisible, disptach);
            changeColorOnVisible(nextSectionColor, !isVisible, disptach);
          }}
        >
          <div className="position-absolute " style={{ top: '25%', left: '15%' }}>
            <FancyTitle title="PULP" />
            <FancyTitle title="GROUND" />

            <p className="font-size-4 pt-4 text-white">
              {`We Are `}
              <span className="font-weight-bold text-highlight-secondary">
                <ReactTyped
                  showCursor={false}
                  backSpeed={30}
                  typeSpeed={30}
                  loop
                  strings={['CREATIVE', 'REACTIVE', 'PULP']}
                />
              </span>
            </p>
          </div>
        </ReactVisibilitySensor>
      </InteractiveLandscape>
    </div>
  );
};

HomeHeroSection.propTypes = {
  nextSectionColor: PropTypes.string,
};
export default HomeHeroSection;
