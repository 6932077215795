/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useWindowWidth } from '@react-hook/window-size';
import ParticleEffects from './lib/scripts/ParticleEffects';

const ParticleHolder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.55);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  > canvas {
    overflow: hidden;
    position: absolute;
    left: ${props => props.left}vw;
    top: ${props => props.top}vh;
    width: 50vw;
    height: 50vh;
    @media (max-width: 1100px) {
      width: 100vw;
      heigth: 100vh;
      left: 0;
      top: 0;
    }
  }
`;

const ParticleEffect = ({ width, height, image, offsetLeft, offsetTop }) => {
  const widowWidth = useWindowWidth();
  const [particleEffect, setParticleEffect] = useState(null);

  useEffect(() => {
    if (particleWrapperRef.current) {
      if (widowWidth <= 1100) {
        setParticleEffect(new ParticleEffects(100, 100, image, 0, 0, particleWrapperRef.current));
      } else {
        setParticleEffect(new ParticleEffects(width, height, image, offsetLeft, offsetTop, particleWrapperRef.current));
      }
    }
  }, [height, image, offsetLeft, offsetTop, width, particleWrapperRef]);
  useEffect(() => {
    if (particleEffect) particleEffect.init();
  }, [particleEffect]);
  useEffect(() => {
    if (particleEffect) {
      if (widowWidth <= 1100) {
        particleEffect.resize(100, 100, 0, 0);
      } else {
        particleEffect.resize(width, height, offsetLeft, offsetTop);
      }
    }
  }, [widowWidth]);
  const particleWrapperRef = useRef();

  return (
    <div>
      <ParticleHolder ref={particleWrapperRef} top={offsetTop} left={offsetLeft} id="particleContainer" />
    </div>
  );
};

ParticleEffect.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
  image: PropTypes.string,
};

export default ParticleEffect;
