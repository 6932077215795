/* eslint-disable eqeqeq */
import WebGLView from './webgl/WebGLView';
import GUIView from './gui/GUIView';

export default class ParticleEffects {
  constructor(width, height, image, offsetLeft, offsetTop, wrapper) {
    this.width = width;
    this.height = height;
    this.image = image;
    this.offsetLeft = offsetLeft;
    this.offsetTop = offsetTop;
    this.wrapper = wrapper;
  }

  init() {
    this.initWebGL(this.image, this.wrapper);
    this.initGUI();
    this.addListeners();
    this.animate();
    this.resize(this.width, this.height, this.offsetLeft, this.offsetTop);

    return this.renderedItem;
  }

  initWebGL(image) {
    this.webgl = new WebGLView(this, image);
    this.wrapper.appendChild(this.webgl.renderer.domElement);
  }

  initGUI() {
    this.gui = new GUIView(this);
  }

  addListeners() {
    this.handlerAnimate = this.animate.bind(this);
  }

  animate() {
    this.update();
    this.draw();

    this.raf = requestAnimationFrame(this.handlerAnimate);
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  update() {
    if (this.gui.stats) this.gui.stats.begin();
    if (this.webgl) this.webgl.update();
    if (this.gui) this.gui.update();
  }

  draw() {
    if (this.webgl) this.webgl.draw();
    if (this.gui.stats) this.gui.stats.end();
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize(width, height, offsetLeft, offsetTop) {
    if (this.width !== width) {
      this.width = width;
      this.height = height;
      this.offsetLeft = offsetLeft;
      this.offsetTop = offsetTop;
    }
    if (this.webgl) this.webgl.resize(width, height, offsetLeft, offsetTop);
  }

  keyup(e) {
    // g
    if (e.keyCode == 71) {
      if (this.gui) this.gui.toggle();
    }
  }
}
