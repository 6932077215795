import React, { useRef, useLayoutEffect, createRef } from 'react';
import runes from 'runes';
import PropTypes from 'prop-types';
import { animateTitles } from './script';

const FancyTitle = ({ title }) => {
  const overlay = useRef();
  const letters = runes(title);
  const titleRefs = letters.map(() => createRef());

  useLayoutEffect(() => {
    const loop = animateTitles(overlay.current, titleRefs);

    return () => clearInterval(loop);
  }, [titleRefs]);

  return (
    <>
      <h2 className="h1 display-1 text-white">
        {letters.map((l, index) => (
          <span key={`${l}${Math.random().toFixed(2)}`} ref={titleRefs[index]}>
            {l}
          </span>
        ))}
      </h2>
      <p className="d-none">{title}</p>
      <div ref={overlay} className="fancy-text-overlay" />
    </>
  );
};

FancyTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FancyTitle;
