import * as THREE from 'three';

import InteractiveControls from './controls/InteractiveControls';
import Particles from './particles/Particles';

export default class WebGLView {
  constructor(app, image) {
    this.app = app;

    this.samples = [image];

    this.initThree();
    this.initParticles();
    this.initControls();

    this.goto(0);
  }

  initThree() {
    // scene
    this.scene = new THREE.Scene();

    // camera
    this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 10000);
    this.camera.position.z = 350;

    // renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

    // clock
    this.clock = new THREE.Clock(true);
  }

  initControls() {
    this.interactive = new InteractiveControls(this.camera, this.renderer.domElement);
  }

  initParticles() {
    this.particles = new Particles(this);
    this.scene.add(this.particles.container);
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  update() {
    const delta = this.clock.getDelta();

    if (this.particles) this.particles.update(delta);
  }

  draw() {
    this.renderer.render(this.scene, this.camera);
  }

  goto(index) {
    // init next
    if (this.currSample == null) this.particles.init(this.samples[index]);
    // hide curr then init next
    else {
      this.particles.hide(true).then(() => {
        this.particles.init(this.samples[index]);
      });
    }

    this.currSample = index;
  }

  next() {
    if (this.currSample < this.samples.length - 1) this.goto(this.currSample + 1);
    else this.goto(0);
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize(width, height, offsetLeft, offsetTop) {
    let vw = 1;

    let vh = 1;

    let x = 0;

    let y = 0;

    if (width && height) {
      vw = (width * 1.0) / 100;
      vh = (height * 1.0) / 100;
    }
    if (!this.renderer) return;
    this.camera.aspect = (window.innerWidth * vw) / (window.innerHeight * vh);
    this.camera.updateProjectionMatrix();

    this.fovHeight = 2 * Math.tan((this.camera.fov * Math.PI) / 180 / 2) * this.camera.position.z;

    this.renderer.setSize(window.innerWidth * vw, window.innerHeight * vh);
    if (offsetLeft && offsetTop) {
      const dx = -(offsetLeft * 1.0) / 100;
      const dy = -(offsetTop * 1.0) / 100;

      x = window.innerWidth * dx;
      y = window.innerHeight * dy;
    }
    if (this.interactive) this.interactive.resize(x, y, window.innerWidth * vw, window.innerHeight * vh);
    if (this.particles) this.particles.resize();
  }
}
