import FancySlider from 'components/FancySlider/FancySlider';
import SEO from 'components/SEO';
import FacebookTypes from 'constants/facebookTypes';
import { getShootingImages } from 'helpers/getShootingImages';
import React, { useRef } from 'react';

const Gallery = () => {
  const images = useRef(getShootingImages()).current;

  return (
    <>
      <SEO
        title="Pulp Ground | Shooting!"
        image="/assets/helmet/Shooting.jpg"
        description="Get high-resolution photos and videos for your business with our professional photographers. We specialize in art and corporate shooting."
        type={FacebookTypes.article}
      />
      <FancySlider effec="1" {...{ images }} />
    </>
  );
};

export default Gallery;
